import React from "react";
import clsx from "clsx";
import { unwidower } from "@utils";

import { Container, Text, Button } from "@atoms";

const LeadCopy = ({
  bgColor,
  button,
  className: _className,
  copy,
  subheading,
}) => {
  const buttonColors = {
    midnight: "carnation",
    carnation: "midnight",
    mint: "midnight",
    white: "carnation",
  };

  return (
    <section
      className={clsx(
        "py-16 md:py-32",
        {
          "bg-mint": bgColor === "mint",
          "bg-carnation": bgColor === "carnation",
          "bg-midnight text-white": bgColor === "midnight",
        },
        _className
      )}
    >
      <Container variant="xxs">
        <div className="flex flex-col items-center space-y-8">
          <Text variant="h7" className="text-center">
            {subheading}
          </Text>
          <Text
            variant="h2sans"
            className={clsx({
              "text-white": bgColor === "carnation",
            })}
          >
            {unwidower(copy)}
          </Text>
          {button?.url && (
            <div>
              <Button to={button.url} color={buttonColors[bgColor]}>
                {button.text || "Learn More"}
              </Button>
            </div>
          )}
        </div>
      </Container>
    </section>
  );
};

LeadCopy.defaultProps = {};

export default LeadCopy;
